import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductDataFilter from "../../../../components/product-data-filter/product-data-filter";
import GenericDataChart from "../../../../GenericPageElements/GenericDataChart";
import GenericPageContainer from "../../../../GenericPageElements/GenericPageContainer";
import GenericProductFilterContainer from "../../../../GenericPageElements/GenericProductFilterContainer";
import endpoints from "../../../../helpers/endpoints";
import request from "../../../../helpers/request";
import S3Modal from "../../../../components/s3Modal/S3Modal";
import hasPermission from "../../../../helpers/permissions";
import GenericDataRow from "../../../../GenericPageElements/GenericDataRow";
import { view } from "@risingstack/react-easy-state";
import { Link } from "react-router-dom";
import BulkBuy from '../../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../../components/cot-alert-bulk-allocate/bulkAllocate';
import BatchRunModal from "../BatchRunModal";
import { CustomizedAxisTickMultiLine } from "../../CompaniesHouseAlerts/CompaniesHouseAlerts";
import '../WholesalerGAPS.scss'; 
import ReactModal from 'react-modal'
import { useHistory } from 'react-router-dom';

export default view(function WholesalerGAPSSummary() {
  const history = useHistory();
  const [filters, setFilters] = useState({});
  const [selectedGroupBy, setSelectectGroupBy] = useState("Status_UPRN");
  const [data, setData] = useState(null);
  const [dates, setDates] = useState([]);
  const [date, setDate] = useState(null);
  const [postcode, setPostcode] = useState(history.location?.state?.postcode || null );
  const [S3Model, setS3Model] = useState('');
  const [postcodeModal, setPostcodeModal] = useState(false);
  const [batchModal, setBatchModal] = useState(false);
  const [bulkModel, setBulkModel] = useState('');
  const [translations, setTranslations] = useState({})

  useEffect(() => {
    request(true).get('/translate?key=wholesaler_gaps')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  
  const applyFilter = (column, value) => {
    setFilters((current) => {
      return {
        ...current,
        [column]: value,
      };
    });
  };

  useEffect(() => {
    request(true)
      .get(`${endpoints.WHOLESALER_GAPS_DATES}?stage=1`)
      .then((e) => {
        setDates(e.data.dates);
        if (e.data.dates.length > 0) setDate(e.data.dates[0].raw);
      });
  }, []);

  useEffect(() => {
    getData();
  }, [filters, selectedGroupBy, date]);

  const runBatch = ((stage) => {
    request(true).post(endpoints.WHOLESALER_GAPS_TRIGGER_BATCH, {
      stage: stage,
      postcode: postcode
    }).then(r => {
      if (r.status === 200) {
        window.alert('Batch run successfully started.')
      }
    }).catch(e => {
      console.log(e);
      window.alert('Failed to run batch.')
    })
  })

  const getData = () => {
    if (!date) return;
    request(true)
      .get(`${endpoints.WHOLESALER_GAPS_DATA}?stage=1&postcode=${postcode ?? ''}%`, {
        params: {
          date: date,
          filter: filters,
          group: selectedGroupBy,
        },
        doesCancel: true,
      })
      .then((e) => {
        setData(e.data);
      });
  }

  return [
    <GenericPageContainer
      title={`${translations['module_title'] || ''} - Summary`}
      titleIconClass={"fak fa-id-business-icon fa-2x colour-primary"}
      titleRight={
        <div>
          <div className="stage-block">
            <button className="active-stage">Stage 1</button>
            <button onClick={() => history.push({pathname: "/portal/wholesaler-voa-gaps/", state: { postcode: postcode }})}>Stage 2</button>
            { hasPermission('WHOLESALER_GAPS', 'BULK-BUY') ?
              <button onClick={() => setBulkModel('batchrun')}>Stage 3</button>
            : 
              <button>Stage 3</button>
            }
            <button onClick={() => history.push({pathname: "/portal/commercial-gaps/", state: { postcode: postcode }})}>Stage 4</button>
          </div>
          <div>
            <button className="navigate" onClick={() => history.push({pathname: "/portal/wholesaler-gaps/search", state: { postcode: postcode }})}>To Search</button>
            { hasPermission('WHOLESALER_GAPS', 'WORKFLOW') ?
              <button className="navigate" onClick={() => history.push({pathname: "/portal/wholesaler-gaps/workflow", state: { postcode: postcode }})}>To Workflow</button>
            : null }
          </div>
        </div>
      }
    >
      <div style={{maxWidth: '20vw', marginBottom: 15}}>
      <GenericDataRow title={"File Date"} contentColour={"white"}>
        <select
          onChange={(_) => setDate(_.target.value)}
          value={date}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
            backgroundColor: "white",
            paddingLeft: "10px",
          }}
        >
          {dates.map((item) => {
            return (
              <option key={item.raw} value={item.raw}>
                {item.pretty}
              </option>
            );
          })}
        </select>
      </GenericDataRow>
      </div>
      <div className='WSG'>
        <GenericDataChart
          dataReady={!!data}
          data={data}
          selectedGroupBy={selectedGroupBy}
          setSelectectGroupBy={setSelectectGroupBy}
          groupingsEndpoint={`${endpoints.WHOLESALER_GAPS_GROUPINGS}?stage=1`}
          sizes={{chart: 600, chartContainer: 625, chartXAxisFontSize: 12, wrapperStyle: { paddingTop: '40px' }}}
          xAxisProps={{interval: 0}}
          customBarTick={CustomizedAxisTickMultiLine}
          overrideTableHeader={"VOA Status"}
        />
        <GenericProductFilterContainer clearFilters={() => setFilters({})}>
          <ProductDataFilter
            filterState={filters}
            applyFilter={applyFilter}
            dataFilterEndpoint={`${endpoints.WHOLESALER_GAPS_FILTERS}?stage=1`}
          />
          <div className='grid grid-columns-2 grid-gap-5 mta'>
              <button className="button bulk-buy-button compact smaller-text" onClick={() => setPostcodeModal(true)}>
                  Input Postcode
              </button>
              { hasPermission('WHOLESALER_GAPS', 'BULK-BUY') ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBatchModal(true)}>
                      Client Choice Update
                  </button>
              : '' }
              { hasPermission('WHOLESALER_GAPS', 'ALLOCATE') ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('allocate')}>
                      Allocate
                  </button>
              : '' }
              { hasPermission('WHOLESALER_GAPS', 'UNALLOCATE') ?
                  <button className="button bulk-buy-button compact smaller-text" onClick={() => setBulkModel('unallocate')}>
                      Unallocate
                  </button>
              : '' }
          </div>
        </GenericProductFilterContainer>
      </div>
    </GenericPageContainer>,
    <S3Modal
      closeModal={() => setS3Model(null)}
      modalMode={S3Model}
      showModal={!!S3Model}
      listingUrl={endpoints.WHOLESALER_GAPS_FILES}
      uploadUrl={endpoints.WHOLESALER_GAPS_FILES}
    />,
    <ReactModal 
    isOpen={postcodeModal} 
    onRequestClose={() => setPostcodeModal(false)}
    className="card bulk-allocate"
    contentLabel="Wholesaler GAPs Postcode Search"
    style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <h3>Postcode Search</h3>
        <input autoFocus value={postcode} onChange={_ => /^[a-zA-Z0-9]{0,3}$/.test(_.target.value) ? setPostcode(_.target.value.toUpperCase()) : null} style={{border: '1px solid gray', width: '100%', borderRadius: 3, marginBottom: '1em'}} />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
          <button className={`button compact smaller-text ${postcode ? "background-primary colour-white" : ''}`} disabled={postcode ? false : true} onClick={() => {getData(postcode || undefined); setPostcodeModal(false)}}>Search</button> 
          { hasPermission('WHOLESALER_GAPS', 'BULK-BUY') ?
            <button className={`button compact smaller-text ${postcode ? "background-primary colour-white" : ''}`} disabled={postcode ? false : true} onClick={() => {getData(postcode || undefined); setPostcodeModal(false); runBatch('S1A')}}>Run Batch</button> 
          : '' }
        </div>
      </div>
    </ReactModal>,
      <ReactModal 
      isOpen={batchModal} 
      onRequestClose={() => setBatchModal(false)}
      className="card bulk-allocate"
      contentLabel="Wholesaler GAPs Batch Run"
      style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}>
        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
          <h3>Batch Run</h3>
          <p>{postcode ? "Are you sure you wish to update the table data?" : "Please input a postcode first."}</p>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center'}}>
            <button className="button compact smaller-text cancel" onClick={ () => setBatchModal(false) }>Cancel</button>
            <button className={`button compact smaller-text ${postcode ? "background-primary colour-white" : ''}`} disabled={postcode ? false : true} onClick={() => {setBatchModal(false); runBatch('S1C')}}>Run Batch</button> 
          </div>
        </div>
      </ReactModal>,
    <BulkAllocate workflowUsersEndpoint={endpoints.WHOLESALER_GAPS_WF_USERS} bulkEndpoint={endpoints.WHOLESALER_GAPS_BULK_BASE} urlParams={{stage: '1', 'postcode': postcode ?? ''}} modalIsOpen={bulkModel === 'allocate'} closeModal={() => setBulkModel('')} filters={filters} date={date} />,
    <BulkAllocate 
        workflowUsersEndpoint={endpoints.WHOLESALER_GAPS_WF_USERS} 
        bulkEndpoint={endpoints.WHOLESALER_GAPS_BULK_BASE} 
        unallocateEndpoint={endpoints.WHOLESALER_GAPS_UNALLOCATE_GET}
        urlParams={{stage: '1', 'postcode': postcode ?? ''}} 
        modalIsOpen={bulkModel === 'unallocate'} 
        mode='unallocate'
        closeModal={() => setBulkModel('')} 
        filters={filters} 
        date={date} 
        reallocate={hasPermission('WHOLESALER_GAPS', 'ALLOCATE') && hasPermission('WHOLESALER_GAPS', 'UNALLOCATE')}
    />,
    <BatchRunModal modalIsOpen={bulkModel === 'batchrun'} closeModal={() => setBulkModel('')} postcode={postcode} />
  ];
})
