import React, { Component } from 'react'
import { view } from '@risingstack/react-easy-state'
import Navigation from '../../../components/navigation/navigation';
import Container from '../../../components/container/container';
import Footer from '../../../components/footer/footer';
import { ReactComponent as HouseIcon } from '../../../assets/House Icon.svg'
import './TariffValidation.scss';
import { Link, withRouter } from 'react-router-dom';
import request from '../../../helpers/request';
import hasPermission from '../../../helpers/permissions';
import endpoints from '../../../helpers/endpoints';
import { BarChart, XAxis, YAxis, Legend, Bar, ResponsiveContainer, CartesianGrid, Tooltip } from 'recharts';
import S3Modal from '../../../components/s3Modal/S3Modal';
import session from '../../../stores/session';
import BulkBuy from '../../../components/cot-alert-bulk-buy/bulkBuy';
import BulkAllocate from '../../../components/cot-alert-bulk-allocate/bulkAllocate';
import HelpVideo from '../../../components/help-video/helpVideo';
import useWSProduct from '../../../helpers/useWSProduct';
import ProductChartInfo from '../../../components/product-chart-info/product-info-chart';
import ProductDataFilter from '../../../components/product-data-filter/product-data-filter'
import Text from 'recharts/lib/component/Text';
import DataGroupings from '../../../components/data-groupings/data-groupings';
import ReactModal from 'react-modal';
import PageTitle from '../../../components/page-header/pageTitle';
import GenericDataChart from '../../../GenericPageElements/GenericDataChart'
import GenericProductFilterContainer from '../../../GenericPageElements/GenericProductFilterContainer'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'

const localised = Intl.NumberFormat('en-GB');

export const CustomizedAxisTickMultiLine = ({x, y, payload}) => {
    return (
       <Text x={x} y={y} width={60} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
    )
};

export class Label extends Component {
    render() {
        const {
            x, y, payload,
        } = this.props;

        console.log('label', x, y, payload)

        return (
            <g transform={`translate(${x},${y})`}>
                <g transform={`translate(-40, 0)`}>
                    { 1 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
                <g transform={`translate(-24, 0)`}>
                    { 2 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
                <g transform={`translate(-8, 0)`}>
                    { 3 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
                <g transform={`translate(8, 0)`}>
                    { 4 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
                <g transform={`translate(24, 0)`}>
                    { 5 <= payload.value ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" className="svg-inline--fa fa-star fa-w-4" role="img" viewBox="0 0 576 512"><path fill="currentColor" d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"/></svg>
                    :
                        <svg width="16px" height="16px" aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" className="svg-inline--fa fa-star fa-w-18" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.1 171.5L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6zM405.8 317.9l27.8 162L288 403.5 142.5 480l27.8-162L52.5 203.1l162.7-23.6L288 32l72.8 147.5 162.7 23.6-117.7 114.8z"></path></svg>
                    }
                </g>
            </g>
        );
    }
}

export default view(
    withRouter(
        class TariffValidation extends Component {
            constructor(props) {
                super(props);

                this.state = {
                    nfiModelOpen: false,
                    dataReady: false,
                    dates: [],
                    date: undefined,
                    data: null,
                    graphData: [],
                    group: undefined,
                    filters: {
                        Type: null,
                        Consumption: [],
                        New_Connection: null,
                        IMD: [],
                        Voids_Age_Band: [],
                        Occupier_Score: [],
                        Assigned: null,
                    },
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        Occupier_Score: {},
                        Consumption: {},
                    },
                    files: [],
                    modalIsOpen: false,
                    modalMode: null,
                    modalMessage: null,
                    bulkBuyModalIsOpen: false,
                    bulkAllocateModalIsOpen: false,
                    translations: [],
                }
            }

            getDates = () => {
                return request(true).get(endpoints.TARIFF_VALIDATION_GET_DATES).then(r => {
                    this.setState({
                        dates: r.data.dates,
                        date: r.data.dates[0].raw
                    });
                }).catch(e => {
                    //console.log(e);
                });
            }

            getGroupings = () => {
                return request(true).get(endpoints.TARIFF_VALIDATION_GROUPINGS).then(e => {
                    this.setState({
                        groupings: e.data,
                        group: e.data.find(_ => _.default)
                    })
                })
            }

            setGroup = (field) => {
                this.setState({
                    group: field
                }, () => {
                    this.query();
                });
            }

            setDate = (ev) => {
                this.setState({
                    date: ev.target.value
                }, () => {
                    this.query();
                });
            }

            filter = (field, value) => {
                this.setState({
                    filters: {
                        ...this.state.filters,
                        [field]: value
                    }
                }, () => {
                    this.query();
                });
            }

            filterArray = (field, value) => {
                this.setState({
                    filterArrays: {
                        ...this.state.filterArrays,
                        [field]: {
                            ...this.state.filterArrays[field],
                            [value]: !this.state.filterArrays[field][value]
                        }
                    }
                }, () => {
                    const newFilter = [];

                    Object.keys(this.state.filterArrays[field]).forEach(key => {
                        if (this.state.filterArrays[field][key]) {
                            newFilter.push(key);
                        }
                    });

                    this.setState({
                        filters: {
                            ...this.state.filters,
                            [field]: newFilter
                        }
                    }, () => {
                        this.query();
                    });
                })
            }

            query = () => {
                this.setState({
                    dataReady: false,
                }, () => {
                    request(true).get(endpoints.TARIFF_VALIDATION_SUMMARY, {
                        params: {
                            date: this.state.date,
                            filter: this.state.filters,
                            group: this.state.group?.target_column
                        },
                        doesCancel: true,
                    }).then(r => {
                        this.setState({
                            data: r.data,
                            dataReady: true
                        })
                    });
                })
            }

            clearFilters = () => {
                this.setState({
                    filters: {
                        Type: null,
                        Consumption: [],
                        New_Connection: null,
                        IMD: [],
                        Voids_Age_Band: [],
                        Occupier_Score: [],
                        Assigned: null,
                    },
                    filterArrays: {
                        IMD: {},
                        Voids_Age_Band: {},
                        Occupier_Score: {},
                        Consumption: {},
                    }
                }, () => {
                    this.query();
                });
            }

            componentDidMount() {
                request(true).get('/translate?key=tariff_validation').then(r => {
                    this.setState({
                        translations: r.data
                    });
                }).catch(e => {
                    console.error(e);
                })
                const _first = this.getDates();
                const _second = this.getGroupings();
                Promise.all([_first, _second]).finally(() => {
                    this.query();
                })
                useWSProduct('tariff_validation');// eslint-disable-line
            }

            openUploadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'upload',
                })
            }

            openDownloadModal = () => {
                this.setState({
                    modalIsOpen: true,
                    modalMode: 'download',
                });
            }

            openUnallocateModal = () => {
                this.setState({
                    modalMode: 'unallocate',
                });
            }

            closeModal = () => {
                this.setState({
                    modalIsOpen: false,
                    modalMode: '',
                })
            }

            openBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: true
                });
            }

            closeBulkBuyModal = () => {
                this.setState({
                    bulkBuyModalIsOpen: false
                });
            }

            openBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: true
                });
            }

            closeBulkAllocateModal = () => {
                this.setState({
                    bulkAllocateModalIsOpen: false
                });
            }

            componentWillUnmount() {
                useWSProduct('tariff_validation', 'unlisten');// eslint-disable-line
            }

            render () {
                return (
                    <GenericPageContainer title={this.state.translations['module_title'] ? this.state.translations['module_title'] : ''} titleRight={
                        <div style={{ display: 'flex' }}>
                            {hasPermission('TARIFF_VALIDATION', 'DOWNLOAD') ?
                                <button className="navigate" onClick={this.openDownloadModal}>Download Return File</button>
                                : null}
                            {hasPermission('TARIFF_VALIDATION', 'UPLOAD') ?
                                <button className="navigate" onClick={this.openUploadModal}>Issue Input File</button>
                                : null}
                            <Link className="navigate" to='/portal/tariff-validation/search'>
                                Search
                            </Link>
                            {(hasPermission('TARIFF_VALIDATION', 'WORKFLOW')) ?
                                <Link className="navigate" to='/portal/tariff-validation/workflow'>
                                    Workflow
                                </Link>
                                : null}
                            <Link className="navigate" to="/portal/tariff-validation/report">
                                Report
                            </Link>
                        </div>
                    }>
                        <Container>
                            <div className="tv">
                                <div className="data-row file-date">
                                    <div className="data-row-title">
                                        {this.state.translations ? this.state.translations['file_date'] : ''}
                                    </div>
                                    <div className="data-row-field">
                                        <select onChange={this.setDate} value={this.state.date}>
                                            {this.state.dates.map(item => {
                                                return (
                                                    <option key={item.raw} value={item.raw}>
                                                        {item.pretty}
                                                    </option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='grid-overview'>
                                    <GenericDataChart
                                        dataReady={this.state.dataReady}
                                        data={this.state.data}
                                        selectedGroupBy={this.state.group}
                                        setSelectectGroupBy={this.setGroup}
                                        groupingsEndpoint={`${endpoints.TARIFF_VALIDATION_GROUPINGS}`}
                                        sizes={{chart: 500, chartContainer: 500, chartXAxisFontSize: 12, wrapperStyle: {paddingTop: '40px'}}}
                                        xAxisProps={{interval: 0}}
                                    />
                                    <GenericProductFilterContainer clearFilters={this.clearFilters} style={{height: 'unset', position: 'sticky', top: 10}}>
                                        <ProductDataFilter applyFilter={this.filter} filterState={this.state.filters} dataFilterEndpoint={endpoints.TARIFF_VALIDATION_DATA_FILTERS} />
                                        <div className='grid grid-columns-2 grid-gap-5 mta'>
                                            {(hasPermission('TARIFF_VALIDATION', 'BULK-BUY')) ?
                                                <button className="button bulk-buy-button" onClick={this.openBulkBuyModal} style={{marginTop: 15}}>
                                                    Bulk Buy
                                                </button>
                                                : ''}
                                            {(hasPermission('TARIFF_VALIDATION', 'ALLOCATE')) ?
                                                <button className="button bulk-buy-button" onClick={this.openBulkAllocateModal} style={{marginTop: 15}}>
                                                    Allocate
                                                </button>
                                                : ''}
                                            { (hasPermission('TARIFF_VALIDATION', 'UNALLOCATE')) ?
                                              <button className="button bulk-buy-button" style={{marginTop: '15px'}} onClick={this.openUnallocateModal}>
                                                Unallocate
                                              </button>
                                              : '' }
                                        </div>
                                    </GenericProductFilterContainer>
                                </div>
                            </div>
                        </Container>
                        <S3Modal showModal={this.state.modalIsOpen} closeModal={this.closeModal} modalMode={this.state.modalMode} listingUrl={endpoints.TARIFF_VALIDATION_FILE_UPLOAD} uploadUrl={endpoints.TARIFF_VALIDATION_FILE_GET}/>
                        <BulkBuy workflowUsersEndpoint={endpoints.TARIFF_VALIDATION_WORKFLOW_USERS} bulkEndpoint={endpoints.TARIFF_VALIDATION_SUMMARY_BULK} modalIsOpen={this.state.bulkBuyModalIsOpen} closeModal={this.closeBulkBuyModal} filters={this.state.filters} date={this.state.date}/>
                        <BulkAllocate workflowUsersEndpoint={endpoints.TARIFF_VALIDATION_WORKFLOW_USERS} bulkEndpoint={endpoints.TARIFF_VALIDATION_SUMMARY_BULK} modalIsOpen={this.state.bulkAllocateModalIsOpen} closeModal={this.closeBulkAllocateModal} filters={this.state.filters} date={this.state.date}/>
                        <BulkAllocate 
                          workflowUsersEndpoint={endpoints.TARIFF_VALIDATION_WORKFLOW_USERS} 
                          bulkEndpoint={endpoints.TARIFF_VALIDATION_SUMMARY_BULK} 
                          unallocateEndpoint={endpoints.TARIFF_VALIDATION_UNALLOCATE_GET} 
                          modalIsOpen={this.state.modalMode === 'unallocate'} 
                          mode={this.state.modalMode} 
                          closeModal={this.closeModal} 
                          filters={this.state.filters} 
                          date={this.state.date} 
                          reallocate={hasPermission('TARIFF_VALIDATION', 'ALLOCATE') && hasPermission('TARIFF_VALIDATION', 'UNALLOCATE')}
                        />,
                        {/* <HelpVideo video={session.company.vca_video_url}/> */}
                        <BulkBuy 
                            closeModal={() => this.setState({ nfiModelOpen: false })} 
                            modalIsOpen={this.state.nfiModelOpen} 
                            bulkEndpoint={endpoints.VOID_COT_ALERTS_NFI_SUMMARY_BULK} 
                            filters={this.state.filters} 
                            date={this.state.date}
                            SentenceFirst={({count}) => <div>
                                <p>The selected data set includes {count} customers. How many would you like to issue to the NFI.</p>
                                <small>If you reduce this number then customers with lower occupier scores will be issued first.</small>
                            </div>}
                            SentenceSecond={({count}) => <div>
                                <p>You have selected { count } customers to issue to the NFI, please press 'Buy Now' to complete this transaction.</p>
                            </div>}
                        />
                    </GenericPageContainer>
                )
            }
        }
    )
)
