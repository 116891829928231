import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import GenericPageContainer from '../../../GenericPageElements/GenericPageContainer'
import endpoints from '../../../helpers/endpoints';
import hasPermission from '../../../helpers/permissions';
import request from '../../../helpers/request';
import {
  CompanyInfo,
  GoogleEvidence,
  CompaniesHouseEvidence,
  YellEvidence,
  AddressDataEvidence,
  FSAEvidence,
  BTEvidence,
  TripAdvisorEvidence,
  DVSAEvidence,
  CQCEvidence, 
  JustEatEvidence, 
  One92Evidence, 
  FacebookEvidence,
  TitleOwnerRow
} from './shared'
import Select from 'react-select'
import GenericDataRow, { GenericDataRowTitle } from '../../../GenericPageElements/GenericDataRow';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';
import PropertyMap from '../../../components/property-map/property-map'
import ReactModal from 'react-modal'
import session from '../../../stores/session';

export default function OccupierValidationWorkflow() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [workflowItems, setWorkflowItems] = useState([])
  const [selectedSPID, setSelectedSPID] = useState('');
  const [mode, setMode] = useState('O')
  const [translations, setTranslations] = useState({})
  const [exclusionReason, setExlusionReason] = useState('')
  const [exclusionFeedback, setExclusionFeedback] = useState()
  const [dates, setDates] = useState([])
  const [selectedDate, setSelectedDate] = useState('')
  useEffect(() => {
    request(true).get('/translate?key=occupier_validation')
    .then(e => {
      setTranslations(e.data)
    })
    }, [])
  const currentWorkflowPosition = useMemo(() => {
    if (!selectedSPID) return 0
    return workflowItems.indexOf(selectedSPID) + 1
  }, [workflowItems, selectedSPID])
  const getData = () => {
    request(true).get(endpoints.OCCUPIED_VALIDATION_LOOKUP, {
      params: {
        SPID: selectedSPID
      }
    }).then(e => {
      setData(e.data)
    })
  }
  const getWorkflowItems = () => {
    setWorkflowItems([])
    setData({})
    setSelectedSPID('')
    setExlusionReason('')
    request(true).get(endpoints.OCCUPIER_VALIDATION_WF_ITEMS, {
      params: {
        selectedDate: selectedDate,
      }
    }).then(e => {
      setWorkflowItems(e.data)
      console.log(e.data.length)
      if (e.data.length > 0) setSelectedSPID(e.data[0])
    })
  }
  useEffect(() => {
    request(true).get(endpoints.OCCUPIER_VALIDATION_DATES).then(r => {
      setDates(r.data.dates)
      setSelectedDate(r.data.dates[0]?.raw)
    })
  }, [])
  useEffect(() => {
    getWorkflowItems()
  }, [selectedDate])
  useEffect(() => {
    if (!selectedSPID) return
    getData()
  }, [selectedSPID])
  const wfDir = (dir = 'prev') => {
    if (dir === 'prev') {
      if (currentWorkflowPosition !== 1) setSelectedSPID(workflowItems[currentWorkflowPosition - 2])
    } else if (currentWorkflowPosition !== workflowItems.length) setSelectedSPID(workflowItems[currentWorkflowPosition])
  }
  const purchase = () => {
    request(true).post(endpoints.OCCUPIER_VALIDATION_PURCHASE, {
      spid: selectedSPID
    }).then(e => {
      getWorkflowItems()
    }).catch(() => {
      console.log('Unable to purchase')
    })
  }
  const exclude = () => {
    request(true).post(endpoints.OCCUPIER_VALIDATION_EXCLUDE, {
      spid: selectedSPID,
      Occupier_Exclusion_Reason: exclusionReason,
      feedback: exclusionFeedback
    }).then(e => {
      getWorkflowItems()
    }).catch(() => {
      console.log('Unable to exclude')
    })
  }
  const updateStatus = (event) => {
    if (!selectedSPID) return 0
    request(true).post(`${endpoints.OCCUPIER_VALIDATION_UPDATE_STATUS}`, {
      customer: data['SPID'],
      status: event.target.value
    }).then(r => {
      setData(r.data)
    }).catch(e => {
      console.log(e);
    })
  }
  const updateCotDate = (date) => {
    const originalDate = data.COT_Date
    const formattedDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    setData({
        ...data,
        COT_Date: formattedDate
    })
    request(true).post(endpoints.OCCUPIER_VALIDATION_UPDATE_COT_DATE, {
        customer: data.SPID,
        date: formattedDate
    }).catch(e => {
        setData({
            ...data,
            COT_Date: originalDate
        })
        console.log(e)
        window.alert("Failed to update COT Date")
    })
  }
  return (
    <GenericPageContainer title={`${translations['module_title'] || ''} - Workflow`}
    titleRight={<div>
        {
          loading ? <CircularProgress size={22} /> : null
        }
        <Link className="navigate" to="/portal/occupier-validation">
            To Overview
        </Link>
        <Link className="navigate" to="/portal/occupier-validation/search">
            To Search
        </Link>
        { (hasPermission('OCCUPIER_VALIDATION', 'DASHBOARD')) ?
            <Link className="navigate" to="/portal/occupier-validation/dashboard">
              To Dashboard
            </Link>
        : null }
      </div>}>
      <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
        <div style={{display: 'grid', gridTemplateColumns: '3fr 1fr', gap: 10}}>
          <div style={{display: 'grid', gridTemplateColumns: 'auto max-content', gap: 10}}>
            <GenericDataRow title={translations['spid']}contentColour={'var(--white)'}>
              <input value={selectedSPID} disabled={true} style={{width: '100%', outline: 'none', border: 'none', backgroundColor: 'var(--white)'}} />
            </GenericDataRow>
            <div style={{display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center'}}>
              <div onClick={() => wfDir('prev')}><i className="colour-primary fas fa-angle-left"></i></div>
              <span style={{width: 40, textAlign: 'center'}}>{currentWorkflowPosition}/{workflowItems.length}</span>
              <div onClick={() => wfDir('next')}><i className="colour-primary fas fa-angle-right"></i></div>
            </div>
          </div>
          <button onClick={purchase} className='navigate' style={{marginLeft: 0}}>Occupier Moved Out</button>
        </div>
        <CompanyInfo translations={translations} mode={mode} setMode={setMode} updateStatus={updateStatus} data={data} workflow={true} dates={dates} selectedDate={selectedDate} setFileDate={setSelectedDate} right={<>
          {/* <button onClick={exclude} className='navigate' style={{marginLeft: 0}}>Occupier Not Moved Out</button> */}
          <select style={{height: 28, marginTop: 7, border: '1px solid var(--primary)', borderRadius: 5}} onChange={_ => setExlusionReason(_.target.value)} value={exclusionReason}>
            { session.company.exclusion_reasons_type === 'Water' ?
              <>
                <option value={''}>Select an exclusion reason</option>
                <option value={'No COT: Current Occupier'}>No COT: Current Occupier</option>
                <option value={'No COT: Name Correction'}>No COT: Name Correction</option>
                <option value={'No COT: Now Vacant'}>No COT: Now Vacant</option>
                <option value={'Multiple Occupier/LL Query'}>Multiple Occupier/LL Query</option>
                <option value={'Address/Data Quality Issue'}>Address/Data Quality Issue</option>
                <option value={'Previous Occupier Provided'}>Previous Occupier Provided</option>
                <option value={'Adjacent Occupier Provided'}>Adjacent Occupier Provided</option>
                <option value={'De-registration'}>De-registration</option>
                <option value={'Insufficient Information'}>Insufficient Information</option>
                <option value={'Other'}>Other</option>
              </>
            : session.company.exclusion_reasons_type === 'Energy' ?
              <>
                <option value={'null'}>Select an exclusion reason</option>
                <option value={'No COT: Current Occupier'}>No COT: Current Occupier</option>
                <option value={'No COT: Name Correction'}>No COT: Name Correction</option>
                <option value={'No COT: Now Vacant'}>No COT: Now Vacant</option>
                <option value={'No COT: Unable to Confirm COT Date'}>No COT: Unable to Confirm COT Date</option>
                <option value={'Multiple Occupier/LL Query'}>Multiple Occupier/LL Query</option>
                <option value={'Address/Data Quality Issue'}>Address/Data Quality Issue</option>
                <option value={'Previous Occupier Provided'}>Previous Occupier Provided</option>
                <option value={'Adjacent Occupier Provided'}>Adjacent Occupier Provided</option>
                <option value={'De-registration'}>De-registration</option>
                <option value={'Awaiting Customer Response'}>Awaiting Customer Response</option>
                <option value={'No Contact Info Provided or Located'}>No Contact Info Provided or Located</option>
                <option value={'Contact Info Available But No Contact Achieved'}>Contact Info Available But No Contact Achieved</option>
              </>
            : ''}
          </select>
        </>} />
        <TitleOwnerRow translations={translations} data={data} mode={mode} updateCotDate={updateCotDate} />
        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <GoogleEvidence data={data} mode={mode} />
          <CompaniesHouseEvidence data={data} mode={mode} />
          <YellEvidence data={data} mode={mode} />
          <AddressDataEvidence data={data} mode={mode} />
        </div>
        <div style={{display: 'grid', gridAutoColumns: '1fr', gridAutoFlow: 'column', gap: 10}}>
          <FSAEvidence data={data} mode={mode} />
          <BTEvidence data={data} mode={mode} />
          <TripAdvisorEvidence data={data} mode={mode} />
          <DVSAEvidence data={data} mode={mode} />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', gap: '.5em', height: '100%'}}>
          <CQCEvidence data={data} mode={mode} />
          <JustEatEvidence data={data} mode={mode} />
          <One92Evidence data={data} mode={mode} />
          <FacebookEvidence data={data} mode={mode} />
        </div>
      </div>

      <div className="secondary-grid" style={{gridTemplateColumns: '1fr 3fr', gridGap: '15px', gap: '15px', display: 'grid', minHeight: '300px'}}>
          <table className="table borders squish super-small-text extra-row-padding">
              <thead>
                  <tr>
                      <th style={{textAlign: 'left'}}>
                          Days
                      </th>
                      <th>
                          Opening Times
                      </th>
                  </tr>
              </thead>
              <tbody>
              {
                  ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map(day => {
                      return (
                          <tr>
                              <td style={{textAlign: "left", width: "100px"}}>{ day }</td>
                              <td style={{minWidth: "unset"}}>{ data?.[mode + "_Google_Opening_Hours"] ? JSON.parse(data?.[mode + "_Google_Opening_Hours"].replaceAll('\'', '"'))[day] : null }</td>
                          </tr>
                      )
                  })
              }
          </tbody>
          </table>
          <div style={{height: 'auto'}}>
              <PropertyMap address={ data ? data.SPID_Address : null } />
          </div>
      </div>
      <ReactModal 
      className="card bulk-buy"
      isOpen={exclusionReason !== ''}>
          <p>You have selected the following exclusion reason:</p>
          <p><strong>{exclusionReason}</strong></p>
          <div style={{ display: 'grid', marginBottom: '1em' }}>
            <p>Additional Feedback:</p>
            <textarea onChange={(event) => setExclusionFeedback(event.target.value.slice(0, 500))} value={exclusionFeedback ?? ''} rows={5} style={{ resize: 'none' }}/>
            <div style={{ marginLeft: 'auto', color: 'gray', fontSize: 12 }}>
              {exclusionFeedback?.length ?? 0}/500
            </div>
          </div>
          <div className='grid grid-columns-2 grid-gap-5'>
              <button className='button compact background-red colour-white' onClick={() => setExlusionReason('')}>Cancel</button>
              <button className='button compact background-primary colour-white' onClick={() => exclude()}>Confirm</button>
          </div>
      </ReactModal>
    </GenericPageContainer>
  )
}
